<template>
  <div class="t_exampaper_manage">
    <div class="stu-module-header">
      <div class="stu-module-title">试卷管理</div>
      <div>
        <el-button type="primary" @click="add">添加试卷</el-button>
      </div>
    </div>
    <div class="content">
      <el-form :model="form" size="medium" inline>
        <el-form-item label="试卷名称">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker
            v-model="form.createTime"
            type="daterange"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="query">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table :data="tableData" :height="height" v-loading="loading">
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in column"
          :label="item.label"
          :prop="item.prop"
          :key="index"
          :min-width="item.width"
          :sortable="item.sortable"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" align="center" width="145">
          <template slot-scope="scope">
            <el-button type="text" @click="onEdit(scope.row)">编辑</el-button>
            <el-button type="text" @click="onDelete(scope.row)">删除</el-button>
            <el-button type="text" @click="onPreview(scope.row)"
              >预览</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  AueryEduTestPaperAll,
  AddEduTestPaper,
  UpdateEduTestPaperById,
  ExamPaperPreview,
} from "@/libs/api/teacher/exampaper";
export default {
  name: "t_exampaper_manage",
  data() {
    return {
      height: window.innerHeight - 400,
      loading: false,
      form: {
        name: "",
        createTime: [],
      },
      column: [
        { label: "试卷名称", prop: "paperName", width: 180 },
        { label: "试卷总分", prop: "totalScore" },
        { label: "创建时间", prop: "createTime", width: 120, sortable: true },
      ],
      tableData: [],
      total: 0,
      pageSize: 20,
      current: 1,
      id: null,
    };
  },
  activated() {
    this.query();
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    query() {
      const p = {
        page: this.current,
        pageSize: this.pageSize,
        startTime: this.form.createTime[0],
        endTime: this.form.createTime[1],
        paperName: this.form.name,
        courseId: this.course.id
      };
      this.loading = true;
      AueryEduTestPaperAll(p)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onEdit(row) {
      if (row) {
        this.$router.push({
          path: "manage/add",
          query: {
            id: row.id,
          },
        });
      }
    },
    onDelete(row) {
      if (row) {
        this.openLoadingView();
        this.$axios_supermallData.get("/hnjxjy-core/eduTestPaper/deleteEduTestById?id="+row.id).then(resp => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功")
            this.loadingView.close();
            this.query();
          }else {
            this.loadingView.close();
          }
        }).catch((e)=>{
          this.$message.error(e)
          this.loadingView.close();
        })
      }
    },
    onPreview(row) {
      if (row) {
        this.$router.push({
          path: "manage/preview",
          query: {
            id: row.id,
          },
        });
      }
    },
    handleCurrentChange(page) {
      if (page) this.current = page;
    },
    add() {
      this.$router.push("manage/add");
    },
  },
};
</script>

<style lang="less" scoped>
.t_exampaper_manage {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }

  .content {
    padding: 20px;
  }
}
</style>